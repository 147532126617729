const setConversationID = (conversationID) => {
  window.sessionStorage.setItem("__zch__zakat-conversation-id", conversationID);
};

const getConversationID = () => {
  return window.sessionStorage.getItem("__zch__zakat-conversation-id");
};

export default {
  setConversationID,
  getConversationID,
};
