const currencies = [
  {
    code: "GBP",
    name: "Pound Sterling",
    left: "£",
    country: "gb",
    color: "#00247d",
  },
  {
    code: "USD",
    name: "United States Dollar",
    left: "$",
    country: "us",
    color: "#3c3b6e",
  },
  {
    code: "CAD",
    name: "Canadian Dollar",
    left: "$",
    country: "ca",
    color: "#ff0000",
  },
  //{
  //    code: "AUD",
  //    name: "Australian Dollar",
  //    left: "$",
  //    country: "au",
  //    color: "#00008b"
  //},
  {
    code: "ZAR",
    name: "South African Rand",
    left: "R",
    country: "za",
    color: "#007749",
  },
];

export default currencies;

export function format(value, currency) {
  const fs = currencies.filter((x) => x.code === currency);
  if (fs.length === 0) {
    fs.push({
      right: ` ${currency}`,
    });
  }

  const c = fs[0];
  return `${c.left || ""}${value.toFixed(2)}${c.right || ""}`;
}
