<template>
  <div class="zch-loading-anim" v-bind:class="getTinyClass">
    <div class="zch-loading-anim-circle" v-bind:class="getTinyClass">
      <div
        v-bind:class="getTinyClass"
        v-bind:style="getColor"
        v-bind:key="key"
        v-for="(circlePart, key) in 4"
      ></div>
    </div>
    <div>{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: "LoadingAnimation",
  props: {
    label: String,
    isTiny: Boolean,
    color: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    getTinyClass() {
      return this.isTiny ? "tiny" : "";
    },
    getColor() {
      return this.color ? `border-color: ${this.color} #0000 #0000 #0000` : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.zch-loading-anim {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat, serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $color-cod-gray;
  @media screen and (max-width: 992px) {
    text-align: center;
  }

  &-circle {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    &.tiny {
      width: 40px;
      height: 40px;
      margin-left: -10px;
      margin-top: -6px;
    }

    & div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid $color-bittersweet;
      border-radius: 50%;
      animation: zch-loading-anim-circle 1.2s cubic-bezier(0.5, 0, 0.5, 1)
        infinite;
      border-color: $color-montecarlo #0000 #0000 #0000;

      &.tiny {
        width: 32px;
        height: 32px;
        border-width: 4px;
      }

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }

    @keyframes zch-loading-anim-circle {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
